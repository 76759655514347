/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetAllTopicsByMonthResponse,
    GetAllTopicsByMonthResponseFromJSON,
    GetAllTopicsByMonthResponseToJSON,
    GetMyTopicResponse,
    GetMyTopicResponseFromJSON,
    GetMyTopicResponseToJSON,
    GetMyTopicsByYearResponse,
    GetMyTopicsByYearResponseFromJSON,
    GetMyTopicsByYearResponseToJSON,
} from '../models';

export interface MyLearningTopicsAllYearMonthGetRequest {
    year: number;
    month: number;
}

export interface MyLearningTopicsYearSemesterGetRequest {
    year: number;
    semester: string;
}

export interface MyTopicIdGetRequest {
    id: number;
}

/**
 * 
 */
export class MyLearningApi extends runtime.BaseAPI {

    /**
     */
    async myLearningTopicsAllYearMonthGetRaw(requestParameters: MyLearningTopicsAllYearMonthGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAllTopicsByMonthResponse>> {
        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling myLearningTopicsAllYearMonthGet.');
        }

        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling myLearningTopicsAllYearMonthGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/my-learning/topics/all/{year}/{month}`.replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))).replace(`{${"month"}}`, encodeURIComponent(String(requestParameters.month))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllTopicsByMonthResponseFromJSON(jsonValue));
    }

    /**
     */
    async myLearningTopicsAllYearMonthGet(requestParameters: MyLearningTopicsAllYearMonthGetRequest, initOverrides?: RequestInit): Promise<GetAllTopicsByMonthResponse> {
        const response = await this.myLearningTopicsAllYearMonthGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async myLearningTopicsYearSemesterGetRaw(requestParameters: MyLearningTopicsYearSemesterGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMyTopicsByYearResponse>> {
        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling myLearningTopicsYearSemesterGet.');
        }

        if (requestParameters.semester === null || requestParameters.semester === undefined) {
            throw new runtime.RequiredError('semester','Required parameter requestParameters.semester was null or undefined when calling myLearningTopicsYearSemesterGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/my-learning/topics/{year}/{semester}`.replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))).replace(`{${"semester"}}`, encodeURIComponent(String(requestParameters.semester))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMyTopicsByYearResponseFromJSON(jsonValue));
    }

    /**
     */
    async myLearningTopicsYearSemesterGet(requestParameters: MyLearningTopicsYearSemesterGetRequest, initOverrides?: RequestInit): Promise<GetMyTopicsByYearResponse> {
        const response = await this.myLearningTopicsYearSemesterGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async myTopicIdGetRaw(requestParameters: MyTopicIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMyTopicResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling myTopicIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/my-topic/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMyTopicResponseFromJSON(jsonValue));
    }

    /**
     */
    async myTopicIdGet(requestParameters: MyTopicIdGetRequest, initOverrides?: RequestInit): Promise<GetMyTopicResponse> {
        const response = await this.myTopicIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
