/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LearningTaskGroup,
    LearningTaskGroupFromJSON,
    LearningTaskGroupFromJSONTyped,
    LearningTaskGroupToJSON,
} from './LearningTaskGroup';
import {
    TrackLeaderResponse,
    TrackLeaderResponseFromJSON,
    TrackLeaderResponseFromJSONTyped,
    TrackLeaderResponseToJSON,
} from './TrackLeaderResponse';

/**
 * 
 * @export
 * @interface GetMyTopicResponse
 */
export interface GetMyTopicResponse {
    /**
     * 
     * @type {number}
     * @memberof GetMyTopicResponse
     */
    trackId?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMyTopicResponse
     */
    trackName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyTopicResponse
     */
    readonly heroImageUrl?: string | null;
    /**
     * 
     * @type {Array<TrackLeaderResponse>}
     * @memberof GetMyTopicResponse
     */
    trackLeaders?: Array<TrackLeaderResponse> | null;
    /**
     * 
     * @type {Array<LearningTaskGroup>}
     * @memberof GetMyTopicResponse
     */
    taskGroups?: Array<LearningTaskGroup> | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyTopicResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMyTopicResponse
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyTopicResponse
     */
    subtitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyTopicResponse
     */
    summary?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyTopicResponse
     */
    monthNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyTopicResponse
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyTopicResponse
     */
    progressPercentage?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyTopicResponse
     */
    isOngoing?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetMyTopicResponse
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GetMyTopicResponse
     */
    endDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyTopicResponse
     */
    dateHeader?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyTopicResponse
     */
    whiteboard?: string | null;
}

export function GetMyTopicResponseFromJSON(json: any): GetMyTopicResponse {
    return GetMyTopicResponseFromJSONTyped(json, false);
}

export function GetMyTopicResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMyTopicResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trackId': !exists(json, 'trackId') ? undefined : json['trackId'],
        'trackName': !exists(json, 'trackName') ? undefined : json['trackName'],
        'heroImageUrl': !exists(json, 'heroImageUrl') ? undefined : json['heroImageUrl'],
        'trackLeaders': !exists(json, 'trackLeaders') ? undefined : (json['trackLeaders'] === null ? null : (json['trackLeaders'] as Array<any>).map(TrackLeaderResponseFromJSON)),
        'taskGroups': !exists(json, 'taskGroups') ? undefined : (json['taskGroups'] === null ? null : (json['taskGroups'] as Array<any>).map(LearningTaskGroupFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'subtitle': !exists(json, 'subtitle') ? undefined : json['subtitle'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'monthNumber': !exists(json, 'monthNumber') ? undefined : json['monthNumber'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'progressPercentage': !exists(json, 'progressPercentage') ? undefined : json['progressPercentage'],
        'isOngoing': !exists(json, 'isOngoing') ? undefined : json['isOngoing'],
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'dateHeader': !exists(json, 'dateHeader') ? undefined : json['dateHeader'],
        'whiteboard': !exists(json, 'whiteboard') ? undefined : json['whiteboard'],
    };
}

export function GetMyTopicResponseToJSON(value?: GetMyTopicResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trackId': value.trackId,
        'trackName': value.trackName,
        'trackLeaders': value.trackLeaders === undefined ? undefined : (value.trackLeaders === null ? null : (value.trackLeaders as Array<any>).map(TrackLeaderResponseToJSON)),
        'taskGroups': value.taskGroups === undefined ? undefined : (value.taskGroups === null ? null : (value.taskGroups as Array<any>).map(LearningTaskGroupToJSON)),
        'id': value.id,
        'title': value.title,
        'subtitle': value.subtitle,
        'summary': value.summary,
        'monthNumber': value.monthNumber,
        'type': value.type,
        'progressPercentage': value.progressPercentage,
        'isOngoing': value.isOngoing,
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'dateHeader': value.dateHeader,
        'whiteboard': value.whiteboard,
    };
}

