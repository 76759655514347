/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetByIdResponse
 */
export interface GetByIdResponse {
    /**
     * 
     * @type {number}
     * @memberof GetByIdResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetByIdResponse
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetByIdResponse
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetByIdResponse
     */
    shortDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetByIdResponse
     */
    heroImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetByIdResponse
     */
    skillLevel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetByIdResponse
     */
    resourceDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetByIdResponse
     */
    resources?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetByIdResponse
     */
    youtubeId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetByIdResponse
     */
    isNew?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetByIdResponse
     */
    hidden?: boolean | null;
}

export function GetByIdResponseFromJSON(json: any): GetByIdResponse {
    return GetByIdResponseFromJSONTyped(json, false);
}

export function GetByIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetByIdResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'shortDescription': !exists(json, 'shortDescription') ? undefined : json['shortDescription'],
        'heroImageUrl': !exists(json, 'heroImageUrl') ? undefined : json['heroImageUrl'],
        'skillLevel': !exists(json, 'skillLevel') ? undefined : json['skillLevel'],
        'resourceDescription': !exists(json, 'resourceDescription') ? undefined : json['resourceDescription'],
        'resources': !exists(json, 'resources') ? undefined : json['resources'],
        'youtubeId': !exists(json, 'youtubeId') ? undefined : json['youtubeId'],
        'isNew': !exists(json, 'isNew') ? undefined : json['isNew'],
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
    };
}

export function GetByIdResponseToJSON(value?: GetByIdResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'shortDescription': value.shortDescription,
        'heroImageUrl': value.heroImageUrl,
        'skillLevel': value.skillLevel,
        'resourceDescription': value.resourceDescription,
        'resources': value.resources,
        'youtubeId': value.youtubeId,
        'isNew': value.isNew,
        'hidden': value.hidden,
    };
}

