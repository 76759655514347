/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SubscribeTopicRequest,
    SubscribeTopicRequestFromJSON,
    SubscribeTopicRequestToJSON,
    UnsubscribeTopicRequest,
    UnsubscribeTopicRequestFromJSON,
    UnsubscribeTopicRequestToJSON,
} from '../models';

export interface TopicsSubscribePostRequest {
    subscribeTopicRequest: SubscribeTopicRequest;
}

export interface TopicsUnsubscribePostRequest {
    unsubscribeTopicRequest: UnsubscribeTopicRequest;
}

/**
 * 
 */
export class TopicsApi extends runtime.BaseAPI {

    /**
     */
    async topicsSubscribePostRaw(requestParameters: TopicsSubscribePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.subscribeTopicRequest === null || requestParameters.subscribeTopicRequest === undefined) {
            throw new runtime.RequiredError('subscribeTopicRequest','Required parameter requestParameters.subscribeTopicRequest was null or undefined when calling topicsSubscribePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Topics/Subscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscribeTopicRequestToJSON(requestParameters.subscribeTopicRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async topicsSubscribePost(requestParameters: TopicsSubscribePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.topicsSubscribePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async topicsUnsubscribePostRaw(requestParameters: TopicsUnsubscribePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.unsubscribeTopicRequest === null || requestParameters.unsubscribeTopicRequest === undefined) {
            throw new runtime.RequiredError('unsubscribeTopicRequest','Required parameter requestParameters.unsubscribeTopicRequest was null or undefined when calling topicsUnsubscribePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Topics/Unsubscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnsubscribeTopicRequestToJSON(requestParameters.unsubscribeTopicRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async topicsUnsubscribePost(requestParameters: TopicsUnsubscribePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.topicsUnsubscribePostRaw(requestParameters, initOverrides);
    }

}
