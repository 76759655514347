/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ConferenceSignupRequest,
    ConferenceSignupRequestFromJSON,
    ConferenceSignupRequestToJSON,
    ConferenceSignupResponse,
    ConferenceSignupResponseFromJSON,
    ConferenceSignupResponseToJSON,
} from '../models';

export interface ConferenceSignupPostRequest {
    conferenceSignupRequest: ConferenceSignupRequest;
}

/**
 * 
 */
export class ConferenceApi extends runtime.BaseAPI {

    /**
     */
    async conferenceSignupGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ConferenceSignupResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/conference-signup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConferenceSignupResponseFromJSON(jsonValue));
    }

    /**
     */
    async conferenceSignupGet(initOverrides?: RequestInit): Promise<ConferenceSignupResponse> {
        const response = await this.conferenceSignupGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async conferenceSignupPostRaw(requestParameters: ConferenceSignupPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.conferenceSignupRequest === null || requestParameters.conferenceSignupRequest === undefined) {
            throw new runtime.RequiredError('conferenceSignupRequest','Required parameter requestParameters.conferenceSignupRequest was null or undefined when calling conferenceSignupPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/conference-signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConferenceSignupRequestToJSON(requestParameters.conferenceSignupRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async conferenceSignupPost(requestParameters: ConferenceSignupPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.conferenceSignupPostRaw(requestParameters, initOverrides);
    }

}
