/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocationType,
    LocationTypeFromJSON,
    LocationTypeFromJSONTyped,
    LocationTypeToJSON,
} from './LocationType';

/**
 * 
 * @export
 * @interface ConferenceSignupResponse
 */
export interface ConferenceSignupResponse {
    /**
     * 
     * @type {number}
     * @memberof ConferenceSignupResponse
     */
    id?: number;
    /**
     * 
     * @type {LocationType}
     * @memberof ConferenceSignupResponse
     */
    location?: LocationType;
    /**
     * 
     * @type {string}
     * @memberof ConferenceSignupResponse
     */
    foodPreferences?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ConferenceSignupResponse
     */
    lastModifiedAt?: Date;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ConferenceSignupResponse
     */
    locationCounts?: { [key: string]: number; } | null;
}

export function ConferenceSignupResponseFromJSON(json: any): ConferenceSignupResponse {
    return ConferenceSignupResponseFromJSONTyped(json, false);
}

export function ConferenceSignupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConferenceSignupResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'location': !exists(json, 'location') ? undefined : LocationTypeFromJSON(json['location']),
        'foodPreferences': !exists(json, 'foodPreferences') ? undefined : json['foodPreferences'],
        'lastModifiedAt': !exists(json, 'lastModifiedAt') ? undefined : (new Date(json['lastModifiedAt'])),
        'locationCounts': !exists(json, 'locationCounts') ? undefined : json['locationCounts'],
    };
}

export function ConferenceSignupResponseToJSON(value?: ConferenceSignupResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'location': LocationTypeToJSON(value.location),
        'foodPreferences': value.foodPreferences,
        'lastModifiedAt': value.lastModifiedAt === undefined ? undefined : (value.lastModifiedAt.toISOString()),
        'locationCounts': value.locationCounts,
    };
}

