/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpcomingLiveSessionResponse
 */
export interface UpcomingLiveSessionResponse {
    /**
     * 
     * @type {string}
     * @memberof UpcomingLiveSessionResponse
     */
    readonly description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpcomingLiveSessionResponse
     */
    readonly link?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UpcomingLiveSessionResponse
     */
    readonly liveTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof UpcomingLiveSessionResponse
     */
    readonly taskId?: number;
}

export function UpcomingLiveSessionResponseFromJSON(json: any): UpcomingLiveSessionResponse {
    return UpcomingLiveSessionResponseFromJSONTyped(json, false);
}

export function UpcomingLiveSessionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpcomingLiveSessionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'liveTime': !exists(json, 'liveTime') ? undefined : (json['liveTime'] === null ? null : new Date(json['liveTime'])),
        'taskId': !exists(json, 'taskId') ? undefined : json['taskId'],
    };
}

export function UpcomingLiveSessionResponseToJSON(value?: UpcomingLiveSessionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

