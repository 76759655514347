import { createContext, useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Toaster, toast } from 'react-hot-toast';

const ToastContext = createContext();

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export const ToastProvider = ({ children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const topPadding = isSmallScreen ? -8 : -2;

  const showToast = (message, severity = 'success') => {
    if (severity === 'success') {
      toast.success(message);
    } else if (severity === 'error') {
      toast.error(message);
    } else {
      toast(message); // default toast
    }
  };

  const value = {
    showToast,
  };

  return (
    <ToastContext.Provider value={value}>
      {children}
      <Toaster
        position={isSmallScreen ? 'top-center' : 'top-right'}
        duration="4000"
        reverseOrder={false}
        toastOptions={{
          style: {
            marginTop: topPadding,
          },
        }}
      />
    </ToastContext.Provider>
  );
};
