/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocationType,
    LocationTypeFromJSON,
    LocationTypeFromJSONTyped,
    LocationTypeToJSON,
} from './LocationType';

/**
 * 
 * @export
 * @interface ConferenceSignupRequest
 */
export interface ConferenceSignupRequest {
    /**
     * 
     * @type {LocationType}
     * @memberof ConferenceSignupRequest
     */
    location?: LocationType;
    /**
     * 
     * @type {string}
     * @memberof ConferenceSignupRequest
     */
    foodPreferences?: string | null;
}

export function ConferenceSignupRequestFromJSON(json: any): ConferenceSignupRequest {
    return ConferenceSignupRequestFromJSONTyped(json, false);
}

export function ConferenceSignupRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConferenceSignupRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'location': !exists(json, 'location') ? undefined : LocationTypeFromJSON(json['location']),
        'foodPreferences': !exists(json, 'foodPreferences') ? undefined : json['foodPreferences'],
    };
}

export function ConferenceSignupRequestToJSON(value?: ConferenceSignupRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'location': LocationTypeToJSON(value.location),
        'foodPreferences': value.foodPreferences,
    };
}

