/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TrackLeaderResponse,
    TrackLeaderResponseFromJSON,
    TrackLeaderResponseFromJSONTyped,
    TrackLeaderResponseToJSON,
} from './TrackLeaderResponse';
import {
    UpcomingLiveSessionResponse,
    UpcomingLiveSessionResponseFromJSON,
    UpcomingLiveSessionResponseFromJSONTyped,
    UpcomingLiveSessionResponseToJSON,
} from './UpcomingLiveSessionResponse';

/**
 * 
 * @export
 * @interface LearningTopicResponse
 */
export interface LearningTopicResponse {
    /**
     * 
     * @type {number}
     * @memberof LearningTopicResponse
     */
    trackId?: number;
    /**
     * 
     * @type {string}
     * @memberof LearningTopicResponse
     */
    trackName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearningTopicResponse
     */
    readonly heroImageUrl?: string | null;
    /**
     * 
     * @type {Array<TrackLeaderResponse>}
     * @memberof LearningTopicResponse
     */
    trackLeaders?: Array<TrackLeaderResponse> | null;
    /**
     * 
     * @type {UpcomingLiveSessionResponse}
     * @memberof LearningTopicResponse
     */
    upcomingLiveSession?: UpcomingLiveSessionResponse;
    /**
     * 
     * @type {number}
     * @memberof LearningTopicResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LearningTopicResponse
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearningTopicResponse
     */
    subtitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearningTopicResponse
     */
    summary?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LearningTopicResponse
     */
    monthNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LearningTopicResponse
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LearningTopicResponse
     */
    progressPercentage?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LearningTopicResponse
     */
    isOngoing?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof LearningTopicResponse
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof LearningTopicResponse
     */
    endDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof LearningTopicResponse
     */
    dateHeader?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearningTopicResponse
     */
    whiteboard?: string | null;
}

export function LearningTopicResponseFromJSON(json: any): LearningTopicResponse {
    return LearningTopicResponseFromJSONTyped(json, false);
}

export function LearningTopicResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LearningTopicResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trackId': !exists(json, 'trackId') ? undefined : json['trackId'],
        'trackName': !exists(json, 'trackName') ? undefined : json['trackName'],
        'heroImageUrl': !exists(json, 'heroImageUrl') ? undefined : json['heroImageUrl'],
        'trackLeaders': !exists(json, 'trackLeaders') ? undefined : (json['trackLeaders'] === null ? null : (json['trackLeaders'] as Array<any>).map(TrackLeaderResponseFromJSON)),
        'upcomingLiveSession': !exists(json, 'upcomingLiveSession') ? undefined : UpcomingLiveSessionResponseFromJSON(json['upcomingLiveSession']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'subtitle': !exists(json, 'subtitle') ? undefined : json['subtitle'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'monthNumber': !exists(json, 'monthNumber') ? undefined : json['monthNumber'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'progressPercentage': !exists(json, 'progressPercentage') ? undefined : json['progressPercentage'],
        'isOngoing': !exists(json, 'isOngoing') ? undefined : json['isOngoing'],
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'dateHeader': !exists(json, 'dateHeader') ? undefined : json['dateHeader'],
        'whiteboard': !exists(json, 'whiteboard') ? undefined : json['whiteboard'],
    };
}

export function LearningTopicResponseToJSON(value?: LearningTopicResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trackId': value.trackId,
        'trackName': value.trackName,
        'trackLeaders': value.trackLeaders === undefined ? undefined : (value.trackLeaders === null ? null : (value.trackLeaders as Array<any>).map(TrackLeaderResponseToJSON)),
        'upcomingLiveSession': UpcomingLiveSessionResponseToJSON(value.upcomingLiveSession),
        'id': value.id,
        'title': value.title,
        'subtitle': value.subtitle,
        'summary': value.summary,
        'monthNumber': value.monthNumber,
        'type': value.type,
        'progressPercentage': value.progressPercentage,
        'isOngoing': value.isOngoing,
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'dateHeader': value.dateHeader,
        'whiteboard': value.whiteboard,
    };
}

