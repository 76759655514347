/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TopicByMonthResponse,
    TopicByMonthResponseFromJSON,
    TopicByMonthResponseFromJSONTyped,
    TopicByMonthResponseToJSON,
} from './TopicByMonthResponse';

/**
 * 
 * @export
 * @interface GetAllTopicsByMonthResponse
 */
export interface GetAllTopicsByMonthResponse {
    /**
     * 
     * @type {Array<TopicByMonthResponse>}
     * @memberof GetAllTopicsByMonthResponse
     */
    topics?: Array<TopicByMonthResponse> | null;
}

export function GetAllTopicsByMonthResponseFromJSON(json: any): GetAllTopicsByMonthResponse {
    return GetAllTopicsByMonthResponseFromJSONTyped(json, false);
}

export function GetAllTopicsByMonthResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllTopicsByMonthResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'topics': !exists(json, 'topics') ? undefined : (json['topics'] === null ? null : (json['topics'] as Array<any>).map(TopicByMonthResponseFromJSON)),
    };
}

export function GetAllTopicsByMonthResponseToJSON(value?: GetAllTopicsByMonthResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'topics': value.topics === undefined ? undefined : (value.topics === null ? null : (value.topics as Array<any>).map(TopicByMonthResponseToJSON)),
    };
}

