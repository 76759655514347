/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LearningTopicResponse,
    LearningTopicResponseFromJSON,
    LearningTopicResponseFromJSONTyped,
    LearningTopicResponseToJSON,
} from './LearningTopicResponse';

/**
 * 
 * @export
 * @interface GetMyTopicsByYearResponse
 */
export interface GetMyTopicsByYearResponse {
    /**
     * 
     * @type {Array<LearningTopicResponse>}
     * @memberof GetMyTopicsByYearResponse
     */
    topics?: Array<LearningTopicResponse> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetMyTopicsByYearResponse
     */
    monthsWithTopicsToAdd?: Array<number> | null;
}

export function GetMyTopicsByYearResponseFromJSON(json: any): GetMyTopicsByYearResponse {
    return GetMyTopicsByYearResponseFromJSONTyped(json, false);
}

export function GetMyTopicsByYearResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMyTopicsByYearResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'topics': !exists(json, 'topics') ? undefined : (json['topics'] === null ? null : (json['topics'] as Array<any>).map(LearningTopicResponseFromJSON)),
        'monthsWithTopicsToAdd': !exists(json, 'monthsWithTopicsToAdd') ? undefined : json['monthsWithTopicsToAdd'],
    };
}

export function GetMyTopicsByYearResponseToJSON(value?: GetMyTopicsByYearResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'topics': value.topics === undefined ? undefined : (value.topics === null ? null : (value.topics as Array<any>).map(LearningTopicResponseToJSON)),
        'monthsWithTopicsToAdd': value.monthsWithTopicsToAdd,
    };
}

