import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { Configuration, NotificationsApi, StatusApi } from 'api';
import { useAuth } from 'authContext';
import { mobileType } from 'utils';
import Unauthorized from 'layout/Unauthorized';
import Authorized from 'layout/Authorized';
import Loader from 'components/Loader';
import config from 'components/Config';
import usePageTracking from 'core/usePageTracking';
import ReactGA from 'react-ga4';

const Login = lazy(() => import('routes/Login'));
const ForgotPassword = lazy(() => import('routes/ForgotPassword'));
const ResetPassword = lazy(() => import('routes/ResetPassword'));
const Invitation = lazy(() => import('routes/Invitation'));
const Bookmarks = lazy(() => import('routes/Bookmarks'));
const Account = lazy(() => import('routes/Account/Account'));
const AccountInfo = lazy(() => import('routes/Account/AccountInfo'));
const Notification = lazy(() => import('routes/Account/Notification'));
const Password = lazy(() => import('routes/Account/Password'));
const MyLearning = lazy(() => import('routes/MyLearning'));
const NotFound = lazy(() => import('routes/NotFound'));
const RedirectToMeeting = lazy(() => import('routes/RedirectToMeeting'));
const Comments = lazy(() => import('routes/Comments'));
const Chat = lazy(() => import('routes/Chat'));
const MyTopic = lazy(() => import('routes/MyTopic'));
const ConferenceSignup = lazy(() => import('routes/ConferenceSignup'));

function App() {
  const navigate = useNavigate();
  const { token, logout, user } = useAuth();

  const configuration = new Configuration({
    basePath: config.API_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const isProduction = config.APP_ENV === 'production';
  console.log('for GA: ', config.APP_ENV);
  if (isProduction) {
    ReactGA.initialize('G-LE1FX5HHVV');
  } else {
    ReactGA.initialize('G-Y15YEHETFD');
  }

  usePageTracking();

  const statusApi = new StatusApi(configuration);
  useEffect(() => {
    const checkMaintenanceStatus = async () => {
      try {
        const resp = await statusApi.statusGet();
        if (resp.isMaintenance) {
          window.location.href = '/maintenance.html';
        }
      } catch (error) {
        console.error(error);
        window.location.href = '/maintenance.html';
      }
    };

    // Initial check
    checkMaintenanceStatus();

    // Set an interval to check the maintenance status every x seconds
    const intervalId = setInterval(checkMaintenanceStatus, 60 * 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const notificationsApi = new NotificationsApi(configuration);
  window.didReceivePushToken = function (token, error) {
    if (token) {
      notificationsApi
        .notificationAddDeviceTokenPost({
          addDeviceTokenRequest: {
            deviceToken: token,
            email: user.email,
            mobileType: mobileType,
          },
        })
        .catch(error => {
          if (error.status === 401) {
            logout(() => navigate('/login'));
          }
        });
    }
  };

  return (
    <>
      <Suspense fallback={<Loader open />}>
        <Routes>
          <Route element={<Unauthorized />}>
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password/:email/:token" element={<ResetPassword />} />
            <Route path="invitation/:guid" element={<Invitation />} />
            <Route path="*" element={<NotFound />} />
            <Route path="meeting" element={<RedirectToMeeting />} />
          </Route>
          <Route element={<Authorized />}>
            <Route path="/" element={<MyLearning />} />
            <Route path="my-learning/:year/:semester" element={<MyLearning />} />
            <Route path="bookmarks" element={<Bookmarks />} />
            <Route path="account" element={<Account />} />
            <Route path="account/info" element={<AccountInfo />} />
            <Route path="account/password" element={<Password />} />
            <Route path="account/notification" element={<Notification />} />
            <Route path="track/:id/comments" element={<Comments />} />
            <Route path="track/:id/chat" element={<Chat />} />
            <Route path="my-topic/:id" element={<MyTopic />} />
            <Route path="/conference-signup" element={<ConferenceSignup />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
