/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnsubscribeTopicRequest
 */
export interface UnsubscribeTopicRequest {
    /**
     * 
     * @type {number}
     * @memberof UnsubscribeTopicRequest
     */
    topicId?: number;
}

export function UnsubscribeTopicRequestFromJSON(json: any): UnsubscribeTopicRequest {
    return UnsubscribeTopicRequestFromJSONTyped(json, false);
}

export function UnsubscribeTopicRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnsubscribeTopicRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'topicId': !exists(json, 'topicId') ? undefined : json['topicId'],
    };
}

export function UnsubscribeTopicRequestToJSON(value?: UnsubscribeTopicRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'topicId': value.topicId,
    };
}

