/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TrackLeaderResponse,
    TrackLeaderResponseFromJSON,
    TrackLeaderResponseFromJSONTyped,
    TrackLeaderResponseToJSON,
} from './TrackLeaderResponse';
import {
    UpcomingLiveSessionResponse,
    UpcomingLiveSessionResponseFromJSON,
    UpcomingLiveSessionResponseFromJSONTyped,
    UpcomingLiveSessionResponseToJSON,
} from './UpcomingLiveSessionResponse';

/**
 * 
 * @export
 * @interface TopicByMonthResponse
 */
export interface TopicByMonthResponse {
    /**
     * 
     * @type {number}
     * @memberof TopicByMonthResponse
     */
    trackId?: number;
    /**
     * 
     * @type {string}
     * @memberof TopicByMonthResponse
     */
    trackName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopicByMonthResponse
     */
    readonly heroImageUrl?: string | null;
    /**
     * 
     * @type {Array<TrackLeaderResponse>}
     * @memberof TopicByMonthResponse
     */
    trackLeaders?: Array<TrackLeaderResponse> | null;
    /**
     * 
     * @type {number}
     * @memberof TopicByMonthResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TopicByMonthResponse
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopicByMonthResponse
     */
    subtitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopicByMonthResponse
     */
    summary?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TopicByMonthResponse
     */
    monthNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TopicByMonthResponse
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TopicByMonthResponse
     */
    progressPercentage?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TopicByMonthResponse
     */
    isOngoing?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof TopicByMonthResponse
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof TopicByMonthResponse
     */
    endDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof TopicByMonthResponse
     */
    whiteboard?: string | null;
    /**
     * 
     * @type {UpcomingLiveSessionResponse}
     * @memberof TopicByMonthResponse
     */
    upcomingLiveSession?: UpcomingLiveSessionResponse;
    /**
     * 
     * @type {boolean}
     * @memberof TopicByMonthResponse
     */
    isSubscribed?: boolean;
}

export function TopicByMonthResponseFromJSON(json: any): TopicByMonthResponse {
    return TopicByMonthResponseFromJSONTyped(json, false);
}

export function TopicByMonthResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicByMonthResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trackId': !exists(json, 'trackId') ? undefined : json['trackId'],
        'trackName': !exists(json, 'trackName') ? undefined : json['trackName'],
        'heroImageUrl': !exists(json, 'heroImageUrl') ? undefined : json['heroImageUrl'],
        'trackLeaders': !exists(json, 'trackLeaders') ? undefined : (json['trackLeaders'] === null ? null : (json['trackLeaders'] as Array<any>).map(TrackLeaderResponseFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'subtitle': !exists(json, 'subtitle') ? undefined : json['subtitle'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'monthNumber': !exists(json, 'monthNumber') ? undefined : json['monthNumber'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'progressPercentage': !exists(json, 'progressPercentage') ? undefined : json['progressPercentage'],
        'isOngoing': !exists(json, 'isOngoing') ? undefined : json['isOngoing'],
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'whiteboard': !exists(json, 'whiteboard') ? undefined : json['whiteboard'],
        'upcomingLiveSession': !exists(json, 'upcomingLiveSession') ? undefined : UpcomingLiveSessionResponseFromJSON(json['upcomingLiveSession']),
        'isSubscribed': !exists(json, 'isSubscribed') ? undefined : json['isSubscribed'],
    };
}

export function TopicByMonthResponseToJSON(value?: TopicByMonthResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trackId': value.trackId,
        'trackName': value.trackName,
        'trackLeaders': value.trackLeaders === undefined ? undefined : (value.trackLeaders === null ? null : (value.trackLeaders as Array<any>).map(TrackLeaderResponseToJSON)),
        'id': value.id,
        'title': value.title,
        'subtitle': value.subtitle,
        'summary': value.summary,
        'monthNumber': value.monthNumber,
        'type': value.type,
        'progressPercentage': value.progressPercentage,
        'isOngoing': value.isOngoing,
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'whiteboard': value.whiteboard,
        'upcomingLiveSession': UpcomingLiveSessionResponseToJSON(value.upcomingLiveSession),
        'isSubscribed': value.isSubscribed,
    };
}

