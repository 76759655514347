/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusResponse
 */
export interface StatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof StatusResponse
     */
    isMaintenance?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof StatusResponse
     */
    serverTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StatusResponse
     */
    utcTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof StatusResponse
     */
    apiVersion?: string | null;
}

export function StatusResponseFromJSON(json: any): StatusResponse {
    return StatusResponseFromJSONTyped(json, false);
}

export function StatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isMaintenance': !exists(json, 'isMaintenance') ? undefined : json['isMaintenance'],
        'serverTime': !exists(json, 'serverTime') ? undefined : (new Date(json['serverTime'])),
        'utcTime': !exists(json, 'utcTime') ? undefined : (new Date(json['utcTime'])),
        'apiVersion': !exists(json, 'apiVersion') ? undefined : json['apiVersion'],
    };
}

export function StatusResponseToJSON(value?: StatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isMaintenance': value.isMaintenance,
        'serverTime': value.serverTime === undefined ? undefined : (value.serverTime.toISOString()),
        'utcTime': value.utcTime === undefined ? undefined : (value.utcTime.toISOString()),
        'apiVersion': value.apiVersion,
    };
}

